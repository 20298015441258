import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private apiService: ApiService
  ) { }

  getAllLessons(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  mainService(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  doApplication(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  checkDiscountCode(data): Observable<any> {
    return this.apiService.post(data)
    .pipe(map(response => response));
  }
  getCountryCodes(data): Observable<any> {
    return this.apiService.post(data)
    .pipe(map(response => response));
  }
}
