import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, MatDialog, ProgressSpinnerMode } from '@angular/material';
import { SozlesmeModalComponent } from '../modals/sozlesme-modal/sozlesme-modal.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  mode: ProgressSpinnerMode = 'indeterminate';

  basvuruForm: FormGroup ;
  snackBarRef: MatSnackBarRef<any> | undefined;
  isLoading=false
  selectedCountryCode: string = '+90'; // Varsayılan olarak Türkiye seçili
  phone: any = ''; // Telefon numarası
  fullName:any = ''; //
  email:any=''; //
  education:any = ''; //
  program:any = ''; //
  heard_from:any=''
  discount_code:any=''
  usingDiscount: boolean=false;
  selectedCity:any='';
  apply_intership:any='';
  intership_type:any='';
  sozlesme:any=''; //
  d_id: any; //
  selectedEdu_id
  cities:any;
  educations:any=[]
  universities:any=[]
  successBool: boolean | undefined;
  selectedEducationGroupId: number;
  country_codes:any;
  openSnackBar(text: string, css: string) {
    this.snackBarRef = this.snackBar.open(text, 'OK', {
      duration: 4000,
      panelClass: [css]
    });
  }

  test() {
    // Seçilen eğitim değiştiğinde education_group_id'yi saklayın
    const selectedEducation = this.educations.find(edu => edu.id === this.basvuruForm.value.education_id);
    if (selectedEducation) {
      this.selectedEducationGroupId = selectedEducation.education_group_id;
    } else {
      this.selectedEducationGroupId = null;

    }

  }
  doApplication() {
    this.isLoading=true;
    
    if(this.usingDiscount==false){
      this.phone=this.selectedCountryCode+this.basvuruForm.value.phone
      this.basvuruForm.value.phone=this.phone;
      this.basvuruForm.value.operation_type = 'insert';
      this.basvuruForm.value.service_type = 'application_on_web';
      this.basvuruForm.value.discount_code_id = 0;
      this.basvuruForm.value.d_id = this.d_id;
      this.service.doApplication(this.basvuruForm.value)
        .subscribe(res => {
          if (res.error) {
            this.openSnackBar(res.errorMsg, 'error-snackbar');
          } else {
            this.successBool = true;
                     if(this.successBool==true)
                        window.open("https://academy.ecodation.com/tesekkurler", "_self");
            this.isLoading=false


          }
        });




    }
    //İndirim kodu kulanılmışsa
    else{
      this.isLoading=true
      const data ={operation_type:'select',service_type:'check_discount_code',code:this.basvuruForm.value.discount_code,
      education_id:this.basvuruForm.value.education_id}

      this.service.checkDiscountCode(data).subscribe(res=>{
     
        if (res.error==false&&(res.data.education_group_id==this.selectedEducationGroupId||res.data.education_id==0)) {

 this.phone=this.selectedCountryCode+this.basvuruForm.value.phone
 this.basvuruForm.value.phone=this.phone;





     this.basvuruForm.value.operation_type = 'insert';
     this.basvuruForm.value.service_type = 'application_on_web';
     this.basvuruForm.value.discount_code_id=res.data.id;

     this.basvuruForm.value.d_id = this.d_id;
     this.service.doApplication(this.basvuruForm.value)
       .subscribe(res => {
         if (res.error) {
         } else {
           this.successBool = true;

                    if(this.successBool==true)
                       window.open("https://academy.ecodation.com/tesekkurler", "_self");
this.isLoading=false


         }
       });


        }
        else {
          this.openSnackBar('Geçersiz İndirim Kodu','error-snackbar');
          this.isLoading=false
        }
      });


    }

  }
valid(){

  if(this.fullName&&this.email&&this.education&&this.heard_from&&this.apply_intership&&this.intership_type&&this.sozlesme){
    return false;
  }else {
    return true
  }
}
getCountryCodes(){
  const data ={
    "operation_type":"select",
    "service_type":"country_codes"
  }
  this.service.getCountryCodes(data).subscribe(res=>{

    this.country_codes=res
  })
}
  constructor(private service:ServiceService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog

    ) {
    this.basvuruForm = this.fb.group({
      'name': ['',
        [
          Validators.required
        ]
      ],

      'phone': ['',
        [
          Validators.required,
          (control) => {
            const countryCode = this.selectedCountryCode;
            const phoneNumber = control.value;
            if (countryCode === '+90') {
              return Validators.pattern(/^5\d{9}$/)(control);
                        } else if (countryCode === '+994') {
              return Validators.minLength(9)(control);
            }
            return null;
          }

        ]
      ],




      'email': ['',
        [
          Validators.required,
          Validators.email
        ]
      ],
      'city': ['',
        [
          Validators.required
        ]
      ],
      'education_id': ['',
        [
          Validators.required
        ]
      ],
      'education': ['',
        [
          Validators.required
        ]
      ],
      'university': ['',
        [
          // Validators.required
        ]
      ],
      'program': ['',
        [
          // Validators.required
        ]
      ],
      'grade': ['',
        [
          // Validators.required
        ]
      ],
      'apply_internship': ['',
        [
          // Validators.required
        ]
      ],
      'heard_from': ['',
        [
          // Validators.required
        ]
      ],
      'internship_type': ['',
        [
          // Validators.required
        ]
      ],
      'sozlesme': ['',
        [
          // Validators.required
        ]
      ],
      'discount_code': ['',
      [
        // Validators.required
      ]
    ],
    'discount_code_id': ['',
      [
        // Validators.required
      ]
    ]
    })

  }
  edu_type_is_uni:any=false
  ngOnInit() {
    this.getAllLessons({ operation_type: 'select', service_type: 'lessons' });
    this.getAllCities({ service_type: 'cities', operation_type: 'select' });
    this.getAllUniversities({ service_type: 'universities', operation_type: 'select' });
    this.d_id = this.activatedRoute.snapshot.paramMap.get('d_id');

    this.basvuruForm.get('education').valueChanges.subscribe((selectedEducation: string) => {

      if (selectedEducation === 'university') {

        this.edu_type_is_uni=true
      } else {
        this.edu_type_is_uni=false

      }
    });
  this.getCountryCodes()
  }
  getDiscountCode(){ }
  usingDiscountCode() {
    this.usingDiscount=!this.usingDiscount;
    if(this.usingDiscount==false){

    }
      }
      openDialog() {
        const dialogRef = this.dialog.open(SozlesmeModalComponent, {
          width: '80%',
          autoFocus: false,

        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }


      getAllLessons(data: any) {
        this.service.getAllLessons(data)
          .subscribe((res: any) =>{
            this.educations = res

          } );
      }
      getAllCities(data: any) {
        this.service.mainService(data)
          .subscribe((res: any) => this.cities = res);
      }
      getAllUniversities(data: any) {
        this.service.mainService(data)
          .subscribe(res => this.universities = res);
      }



}
