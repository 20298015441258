import { Component, OnInit } from '@angular/core';
/* import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from './service.service';
import { ActivatedRoute, ParamMap } from '@angular/router'; */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ecodation-form';

  constructor() { }

}
