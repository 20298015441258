import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mobil-programlama',
  templateUrl: './mobil-programlama.component.html',
  styleUrls: ['./mobil-programlama.component.scss']
})
export class MobilProgramlamaComponent implements OnInit {

  basvuruForm: FormGroup;
  educations: [];
  d_id: string;
  snackBarRef: MatSnackBarRef<any>;

  constructor(
    private fb: FormBuilder,
    private serviceService: ServiceService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    this.basvuruForm = this.fb.group({
      'name': ['',
        [
          Validators.required
        ]
      ],
      'phone': ['',
        [
          Validators.required
        ]
      ],
      'email': ['',
        [
          Validators.required
        ]
      ],
      'city': ['',
        [
          Validators.required
        ]
      ],
      'education': ['',
        [
          // Validators.required
        ]
      ],
      'university': ['',
        [
          // Validators.required
        ]
      ],
      'program': ['',
        [
          // Validators.required
        ]
      ],
      'apply_internship': ['',
        [
          // Validators.required
        ]
      ],
      'heard_from': ['',
        [
          // Validators.required
        ]
      ],
      'internship_type': ['',
        [
          // Validators.required
        ]
      ]
    })
  }

  ngOnInit() {
    this.getAllLessons({ operation_type: 'select', service_type: 'lessons' });
    this.d_id = this.activatedRoute.snapshot.paramMap.get('d_id');
    console.log(this.d_id);
    
  }

  doApplication() {
    this.basvuruForm.value.operation_type = 'insert';
    this.basvuruForm.value.service_type = 'application_on_web';
    this.basvuruForm.value.d_id = this.d_id;
    this.basvuruForm.value.education_id = '1';
    this.serviceService.doApplication(this.basvuruForm.value)
      .subscribe(res => {
        if (res.error) {
          this.openSnackBar(res.errorMsg, 'error-snackbar');
        } else {
          this.openSnackBar(res.errorMsg, 'success-snackbar');
/*           this.snackBarRef.afterDismissed().subscribe(() => {
            window.open("https://www.ecodation.com", "_self");
          }); */
        }
        console.log(res);
      });
  }

  getAllLessons(data) {
    this.serviceService.getAllLessons(data)
      .subscribe(res => this.educations = res);
  }

  openSnackBar(text, css) {
    this.snackBarRef = this.snackBar.open(text, 'OK', {
      duration: 2000,
      panelClass: [css]
    });
  }

}
